import React from 'react';
import {
  ContainerLogin,
  FormLogin,
  LoginNavBar,
  FormContent,
  ContainerFormCampo,
  FormInput,
  BotaoLogin,
  ForgotPassword,
  FooterContainer,
} from './style';
import Logo from '../../assets/logo.svg';
import { Link } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';

const Login: React.FC = () => {
  return (
    <ContainerLogin>
      <LoginNavBar>
        <img title="Logo" src={Logo} />
      </LoginNavBar>
      <FormLogin>
        <h2>Olá!</h2>
        <p>Entre na sua conta para continuar</p>

        <FormContent>
          <form>
            <ContainerFormCampo>
              <label>Email</label>
              <FormInput
                placeholder="Digite seu e-mail..."
                type="email"
                autoComplete="work email"
              />
            </ContainerFormCampo>
            <ContainerFormCampo>
              <label>Senha</label>
              <FormInput
                placeholder="Digite sua senha..."
                type="password"
                autoComplete="current-password"
              />
            </ContainerFormCampo>
            <Link to={'/d/agenda'}>
              <BotaoLogin>Login</BotaoLogin>
            </Link>
          </form>
          <ForgotPassword>
            <p>
              Esqueceu sua senha ? Clique{' '}
              <span>
                <Link to={'/forgot'}>aqui</Link>
              </span>
            </p>
            <br />
            <p>
              <span>
                <Link to={'first-access'}>Primeiro acesso</Link>
              </span>
            </p>
          </ForgotPassword>
        </FormContent>
      </FormLogin>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </ContainerLogin>
  );
};

export default Login;
