import React, { useState } from 'react';
import {
  Sidebar,
  Logo,
  Menu,
  MenuItem,
  StyledNavLink,
  SubMenu,
  SubMenuItem,
  DropdownToggle,
  DropdownIcon,
  BottomMenu,
  ToggleButton,
} from './style';
import logoImage from '../../assets/logo.svg';
import contact from './assets/Contacts.svg';
import calendar from './assets/Calendar.svg';
import user from './assets/Users.svg';
import trending from './assets/Trending_up.svg';
import setinha from './assets/Seta.svg';
import settings from './assets/Settings.svg';
import headphones from './assets/Headphones.svg';

import miniLogo from '../../../../assets/logo_fadein.png';

const Navbar: React.FC = () => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const toggleSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <Sidebar isMinimized={isMinimized}>
      <Logo>
        {!isMinimized ? (
          <img src={logoImage} alt="Haaify Logo" />
        ) : (
          <img src={miniLogo} alt="Haaify Logo" />
        )}
      </Logo>
      <ToggleButton onClick={toggleSidebar}>
        {isMinimized ? '>' : '<'}
      </ToggleButton>
      <Menu>
        {/* <MenuItem>
          <StyledNavLink to="/d/agenda">
            <img src={contact} alt="" />
            {!isMinimized && 'Minha agenda'}
          </StyledNavLink>
        </MenuItem>
        <MenuItem>
          <StyledNavLink to="/d/calendario">
            <img src={calendar} alt="" />
            {!isMinimized && 'Calendário'}
          </StyledNavLink>
        </MenuItem> */}
        {/* <MenuItem>
          <DropdownToggle onClick={toggleSubMenu}>
            <img src={user} alt="" />
            {!isMinimized && 'Clientes'}
            {!isMinimized && (
              <DropdownIcon src={setinha} alt="" isOpen={isSubMenuOpen} />
            )}
          </DropdownToggle>
          {isSubMenuOpen && !isMinimized && (
            <SubMenu>
              <SubMenuItem>
                <StyledNavLink to="/d/clientes/novo">
                  Novo cliente
                </StyledNavLink>
              </SubMenuItem>
              <SubMenuItem>
                <StyledNavLink to="/d/clientes/ver">Ver clientes</StyledNavLink>
              </SubMenuItem>
            </SubMenu>
          )}
        </MenuItem>
        <MenuItem>
          <StyledNavLink to="/d/dash">
            <img src={trending} alt="" />
            {!isMinimized && 'Dashboard'}
          </StyledNavLink>
        </MenuItem> */}

        <MenuItem>
          <StyledNavLink to="/d/enviomsgs">
            <img src={calendar} alt="" />
            {!isMinimized && 'Disparo'}
          </StyledNavLink>
        </MenuItem>
      </Menu>
      <BottomMenu>
        {/* <MenuItem>
          <StyledNavLink to="/d/configuracoes">
            <img src={settings} alt="" />
            {!isMinimized && 'Configurações'}
          </StyledNavLink>
        </MenuItem>
        <MenuItem>
          <StyledNavLink to="/d/ajuda">
            <img src={headphones} alt="" />
            {!isMinimized && 'Ajuda e Suporte'}
          </StyledNavLink>
        </MenuItem> */}
      </BottomMenu>
    </Sidebar>
  );
};

export default Navbar;
