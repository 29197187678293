import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import Navbar from './pages/Dashboard/components/Navbar/Navbar';
import Forgot from './pages/Forgot/Forgot';
import Mensagens from './pages/Mensagens/Mensagens';
import { DashFlex } from './pages/Dashboard/components/Navbar/style';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route
            path="/d/*"
            element={
              <DashFlex>
                <Navbar />
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/enviomsgs" element={<Mensagens />} />
                </Routes>
              </DashFlex>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
