import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  BotaoLogin,
  ContainerFormCampo,
  ContainerLogin,
  FooterContainer,
  FormContent,
  FormInput,
  FormLogin,
  LoginNavBar,
} from './style';
import Logo from '../../assets/logo.svg';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';

const Forgot: React.FC = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNewForm, setShowNewForm] = useState(false);
  const [code, setCode] = useState(Array(6).fill(''));

  const handleSendClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowNewForm(true);
  };

  const handleCodeChange = (index: number, value: string) => {
    if (/^[0-9]?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
    }
  };

  return (
    <ContainerLogin>
      <LoginNavBar>
        <img title="Logo" src={Logo} />
      </LoginNavBar>
      <FormLogin>
        <h2>
          {showNewForm
            ? 'Crie sua nova senha'
            : showConfirmation
              ? 'Confirme o Código'
              : 'Envie o seu e-mail'}
        </h2>
        <p>
          {showNewForm
            ? 'Crie uma senha forte e não se esqueça de anotá-la desta vez, ok? 😆'
            : showConfirmation
              ? 'Digite o código enviado para o seu e-mail.'
              : 'Digite o seu e-mail utilizado na plataforma.'}
        </p>
        <br />
        <FormContent>
          <form>
            {!showConfirmation && !showNewForm ? (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <ContainerFormCampo>
                  <label>Email</label>
                  <FormInput
                    placeholder="Digite seu e-mail..."
                    type="email"
                    autoComplete="work email"
                  />
                </ContainerFormCampo>
                <BotaoLogin onClick={handleSendClick}>Enviar</BotaoLogin>
              </motion.div>
            ) : showNewForm ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
                key="new-form"
              >
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  key="password-field"
                >
                  <ContainerFormCampo>
                    <label>Senha nova</label>
                    <FormInput
                      placeholder="Digite sua nova senha..."
                      type="password"
                    />
                  </ContainerFormCampo>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  key="confirm-password-field"
                >
                  <ContainerFormCampo>
                    <label>Confirme sua nova senha</label>
                    <FormInput
                      placeholder="Confirme sua nova senha..."
                      type="password"
                    />
                  </ContainerFormCampo>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <Link to={'/login'}>
                    <BotaoLogin style={{ marginTop: '20px', width: '100%' }}>
                      Enviar
                    </BotaoLogin>
                  </Link>
                </motion.div>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={{
                    hidden: { opacity: 0, y: 20 },
                    visible: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delayChildren: 0.2,
                        staggerChildren: 0.1,
                      },
                    },
                  }}
                  style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                  }}
                >
                  {code.map((digit, index) => (
                    <motion.div
                      key={index}
                      variants={{
                        hidden: { opacity: 0, y: 20 },
                        visible: { opacity: 1, y: 0 },
                      }}
                    >
                      <FormInput
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) =>
                          handleCodeChange(index, e.target.value)
                        }
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          textIndent: '0px',
                        }}
                      />
                    </motion.div>
                  ))}
                </motion.div>
                <BotaoLogin
                  onClick={handleConfirmClick}
                  style={{ marginTop: '20px', width: '100%' }}
                >
                  Confirmar
                </BotaoLogin>
              </motion.div>
            )}
          </form>
        </FormContent>
      </FormLogin>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </ContainerLogin>
  );
};

export default Forgot;
